import { is } from "date-fns/locale";
import {
  DealTaxEquityExcludedData,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferPortfolioForm,
  IDealTaxEquityForm,
} from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_TAX_EQUITY_TYPES {
  YBF = "Yield Based Flip",
  FF = "Fixed Flip",
  // SL = "Sale-Leaseback",
  PR = "Prorata",
}

export const DEAL_TAX_EQUITY_TYPE_OPTIONS = enumToOptions(
  DEAL_TAX_EQUITY_TYPES,
  ["SL"],
);

export enum DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS {
  REG = "Regular Flip",
  P_99_67_99_5 = "99/67/99 Flip",
  PRORATA = "Percentage Interest",
}

export const DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS_OPTIONS = enumToOptions(
  DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS,
);

export enum DEAL_TAX_EQUITY_PREF_RETURN_TYPES {
  NONE = "None",
  PFCASH = "% of Total Cash",
  PCTINV = "% of Investment/Yr",
}

export const DEAL_TAX_EQUITY_PREF_RETURN_TYPE_OPTIONS = enumToOptions(
  DEAL_TAX_EQUITY_PREF_RETURN_TYPES,
);

export enum DEAL_TE_SOLVE_FORS {
  TEQAMT = "Investment Amount",
  PFCS = "Pre-Flip Cash Split",
  FDATE = "Target Flip Term",
  // FIRR = "Flip IRR",
  IRR = "IRR",
  // ITCMULT = "ITC Multiple",
  // PFPR = "Pre-Flip Pref Return",
  // FROI = "Flip ROI",
  AFDATE = "Actual Flip Date",
}

export const DEAL_TE_SOLVE_FOR_OPTIONS = enumToOptions(DEAL_TE_SOLVE_FORS);

export enum DEAL_TE_BUYOUT_TYPES {
  FLDATE = "Flip Date",
  NOBUYO = "No Buyout",
}

export const DEAL_TE_BUYOUT_TYPES_OPTIONS = enumToOptions(DEAL_TE_BUYOUT_TYPES);

export enum DEAL_TE_BUYOUT_CALC_METHOD_TYPES {
  TGTRET = "Target Return",
  DISCRT = "Discount Rate",
  POI = "Percentage of Investment",
  SPA = "Specified Amount",
}

export const DEAL_TE_BUYOUT_CALC_METHOD_TYPES_OPTIONS = enumToOptions(
  DEAL_TE_BUYOUT_CALC_METHOD_TYPES,
);

export enum DEAL_TE_FLIP_ROI_INPUT_TYPES {
  AT = "After-Tax IRR",
  PT = "Pre-Tax IRR",
}

export const DEAL_TE_FLIP_ROI_INPUT_TYPES_OPTIONS = enumToOptions(
  DEAL_TE_FLIP_ROI_INPUT_TYPES,
);

export enum DEAL_TE_BUYOUT_IRR_INPUT_TYPES {
  FULLATC = "After-Tax IRR (Full-Term)",
  FLIPATC = "After-Tax IRR (Flip-Term)",
  SPECIRR = "Specify After-Tax Return",
  FULLPTC = "Pre-Tax IRR (Full-Term)",
  FLIPPTC = "Pre-Tax IRR (Flip-Term)",
  SPECPTC = "Specify Pre-Tax Return",
}

export const DEAL_TE_BUYOUT_IRR_INPUT_TYPES_OPTIONS = enumToOptions(
  DEAL_TE_BUYOUT_IRR_INPUT_TYPES,
);

export enum DEAL_TE_INPUT_METHOD {
  PCTFMV = "Percentage of FMV",
  DLRAMT = "Dollar Amount",
  ITCMU = "ITC Multiple",
}

export const DEAL_TE_INPUT_METHOD_OPTIONS = enumToOptions(DEAL_TE_INPUT_METHOD);

export enum DEAL_IC_LOSS_REALLOCATION_METHOD_TYPES {
  POSTF = "Reallocate Losses Post-Flip",
  POSTR = "Reallocate Losses Post-Recapture",
  THROU = "Reallocate Losses Throughout",
}

export const DEAL_IC_LOSS_REALLOCATION_METHOD_TYPES_OPTIONS = enumToOptions(
  DEAL_IC_LOSS_REALLOCATION_METHOD_TYPES,
);

export enum DEAL_ITC_RECOGNITION_METHOD {
  RTD = "Remaining Tax Dates",
  SD = "Specified Date",
}

export const DEAL_ITC_RECOGNITION_METHOD_OPTIONS = enumToOptions(
  DEAL_ITC_RECOGNITION_METHOD,
);

export enum CARRYFORWARD_CREDITS {
  NO = "No",
  YES_2018 = "Yes (2018 Tax Reform)",
  YES = "Yes (100%)",
}

export const CARRYFORWARD_CREDITS_OPTIONS = enumToOptions(CARRYFORWARD_CREDITS);

export enum ACCOUNTING_METHODS {
  HLBV = "HLBV",
  PAM = "PAM",
}

export const ACCOUNTING_METHODS_OPTIONS = enumToOptions(ACCOUNTING_METHODS);

export enum SUSPEND_PRE_TAX_BOOK_LOSS {
  YES = "Yes",
  NO = "No",
}

export const SUSPEND_PRE_TAX_BOOK_LOSS_OPTIONS = enumToOptions(
  SUSPEND_PRE_TAX_BOOK_LOSS,
);

export enum TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME {
  YES = "Yes",
  NO = "No",
  YES_DTA = "Yes (with DTA)",
}

export const TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME_OPTIONS = enumToOptions(
  TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME,
);

export enum DEAL_TAX_CREDIT_TRANSFER_PERIODICITY {
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export const DEAL_TAX_CREDIT_TRANSFER_PERIODICITY_OPTIONS = enumToOptions(
  DEAL_TAX_CREDIT_TRANSFER_PERIODICITY,
);

export enum DISTRIBUTION_PERIODICITY {
  MO = "Monthly",
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export const DISTRIBUTION_PERIODICITY_OPTIONS = enumToOptions(
  DISTRIBUTION_PERIODICITY,
);

// map of solve for option to fields to ignore.
export const DEAL_TAX_EQUITY_FORM_FIELDS_TO_EXCLUDE: DealTaxEquityExcludedData =
  {
    YBF: {
      // ITCMULT: [],
      // FROI: [],
      TEQAMT: [
        "percent_of_fmv",
        "amount_total",
        "flip_roi",
        "target_flip_date",
        "buyout_date",
        "cash_tax_split_percent",
      ],
      PFCS: [
        "amount_total",
        "target_flip_date",
        "flip_roi",
        "pre_flip_cash_split_percent",
        "buyout_date",
        "cash_tax_split_percent",
      ],
      FDATE: [
        "amount_total",
        "target_flip_term",
        "flip_roi",
        "target_flip_date",
        "buyout_date",
        "cash_tax_split_percent",
      ],
      AFDATE: [],
      IRR: [],
      // IRR: [
      //   "target_flip_irr",
      //   "itc_multiple",
      //   "flip_roi",
      //   "target_flip_date",
      //   "buyout_date",
      // ],
    },
    FF: {
      TEQAMT: [],
      PFCS: [],
      // IRR: [],
      // ITCMULT: [
      //   "amount_total",
      //   "target_flip_irr",
      //   "pre_flip_cash_split_percent",
      //   "itc_multiple",
      //   "target_flip_date",
      //   "buyout_date",
      // ],
      FDATE: [],
      AFDATE: [],
      IRR: ["target_flip_irr"],
      // FROI: [
      //   "amount_total",
      //   "target_flip_irr",
      //   "pre_flip_cash_split_percent",
      //   "flip_roi",
      //   "target_flip_date",
      //   "buyout_date",
      // ],
    },
    PR: {
      // FROI: [],
      TEQAMT: [
        "percent_of_fmv",
        "amount_total",
        "flip_roi",
        "target_flip_date",
        "buyout_date",
      ],
      PFCS: [
        "amount_total",
        "target_flip_date",
        "flip_roi",
        "pre_flip_cash_split_percent",
        "buyout_date",
      ],
      FDATE: [
        "amount_total",
        "target_flip_term",
        "flip_roi",
        "target_flip_date",
        "buyout_date",
      ],
      AFDATE: [],
      IRR: [
        "target_flip_irr",
        "target_flip_term",
        "target_flip_date",
        "pre_flip_cash_split_percent",
        "pre_flip_tax_split_percent",

        // constraints
        "pre_tax_irr_floor",
        "dro_cap",
        "roi_floor_full_term",
        "roi_floor_buyout_term",
        // buyout
        "buyout_type",
        "buyout_irr",
        "buyout_irr_input_type",
        "buyout_calculation_method",
        "buyout_amount_discount_rate",
      ],
    },
    // IL: {
    //   TEQAMT: [],
    //   PFCS: [],
    //   IRR: [],
    //   ITCMULT: [],
    //   FDATE: [],
    //   FROI: [],
    // },
    // SL: {
    //   TEQAMT: [],
    //   PFCS: [],
    //   IRR: [],
    //   ITCMULT: [],
    //   FDATE: [],
    //   FROI: [],
    // },
  };

export const DEAL_TAX_EQUITY_FIELDS_TO_HIDE: DealTaxEquityExcludedData = {
  YBF: {
    TEQAMT: ["percent_of_fmv", "cash_tax_split_percent"],
    PFCS: ["pre_flip_cash_split_percent", "cash_tax_split_percent"],
    FDATE: ["target_flip_term", "target_flip_date", "cash_tax_split_percent"],
    AFDATE: ["percent_of_fmv", "cash_tax_split_percent"],
    IRR: ["percent_of_fmv", "cash_tax_split_percent"],
    // FROI: [],
  },
  FF: {
    TEQAMT: [],
    PFCS: [],
    FDATE: [],
    AFDATE: [],
    IRR: ["target_flip_irr"],
    // FROI: ["flip_roi"],
  },
  PR: {
    TEQAMT: [],
    PFCS: [],
    FDATE: ["target_flip_term", "target_flip_date"],
    AFDATE: ["percent_of_fmv"],
    IRR: [
      "target_flip_irr",
      "target_flip_term",
      "target_flip_date",
      "pre_flip_cash_split_percent",
      "pre_flip_tax_split_percent",

      // constraints
      "pre_tax_irr_floor",
      "dro_cap",
      "roi_floor_full_term",
      "roi_floor_buyout_term",
      // buyout
      "buyout_type",
      "buyout_irr",
      "buyout_irr_input_type",
      "buyout_calculation_method",
      "buyout_amount_discount_rate",
    ],
    // FROI: ["flip_roi"],
  },
  // IL: {
  //   TEQAMT: [],
  //   PFCS: [],
  //   IRR: [],
  //   ITCMULT: [],
  //   FDATE: [],
  //   FROI: [],
  // },
  // SL: {
  //   TEQAMT: [],
  //   PFCS: [],
  //   IRR: [],
  //   ITCMULT: [],
  //   FDATE: [],
  //   FROI: [],
  // },
};

export const DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE: IDealTaxCreditTransferForm =
  {
    id: "",
    payment_dates_lag: "",
    payment_date_num_1: "",
    payment_periodicity: "",
    payment_rate: "",
    placed_in_service_date: "",
    pre_filing_date: "",
    transfer_portion: "",
    isPTC: false,
    isITC: false,
    creditStartDate: "",
    creditEndDate: "",
  };

export const DEAL_TAX_EQUITY_FORM_DEFAULT_STATE: IDealTaxEquityForm = {
  amount_total: "",
  buyout_date: "",
  dro_cap: "",
  loss_reallocation_method: "",
  percent_of_fmv: "",
  post_flip_cash_split_percent: "",
  post_flip_tax_split_percent: "",
  pre_flip_tax_split_percent: "",
  distribution_periodicity: "",
  pre_flip_cash_split_percent: "",
  cash_tax_split_percent: "",
  share_of_cash_percent: "",
  solve_for: "",
  target_flip_date: "",
  target_flip_irr: "",
  target_flip_term: "",
  pre_tax_irr_floor: "",
  type: "",
  buyout_irr: "",
  buyout_amount_discount_rate: "",
  buyout_calculation_method: "",
  buyout_irr_input_type: "",
  target_flip_irr_type: "",
  buyout_type: "",
  flip_roi: "",
  roi_floor_buyout_term: "",
  roi_floor_full_term: "",
  tax_allocation_method: "",
  preferred_return_type: "",
  preferred_return_percent: "",
  sponsor_return_of_capital: "",
  tax_equity_funding_date: "",
  tax_equity_input_method: "",
  tax_equity_input_value: "",
  itc_recognition_method: "",
  itc_recognition_lag: "",
  carryforward_credits_and_losses: "",
  tax_step_down_period: "",
  tax_step_tax_split: "",
  target_flip_cash_split: "",
  target_flip_tax_split: "",
  accounting_method: "",
  accounting_year: "",
  tax_credits_in_pre_tax_book_income: "",
  suspend_pre_tax_book_loss: "",
  federal_tax_rate: "",
  new_federal_tax_rate: "",
  federal_tax_rate_change_year: null,
  change_in_federal_tax_rate: "",
  buyout_percentage_of_investment: "",
  buyout_specified_amount: "",
  has_itc_projects: "",
};

export const UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE: IDealTaxCreditTransferPortfolioForm =
  {
    transfer_maximum_payment: "",
    transfer_minimum_roi: "",
    transfer_discount_rate: "",
  };

export const DEAL_PROJECT_COST_COMPONENT_TYPES = {
  development: {
    label: "Development Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: false,
  },
  acquisition: {
    label: "Acquisition Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: false,
  },
  interconnection: {
    label: "Interconnection Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: false,
  },
  build: {
    label: "Build Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: false,
  },
  transaction: {
    label: "Transaction Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: false,
  },
  land: {
    label: "Land Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: false,
  },
  other: {
    label: "Other Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: false,
  },
  project_indirect: {
    label: "Deal Project Indirect Cost",
    type: "dollar",
    is_bold: false,
  },
  total_cost: {
    label: "Total Project Cost",
    type: "dollar/dollar_per_wdc",
    is_bold: true,
  },
  fmv_step_up_percentage: {
    label: "FMV Step Up Percent",
    type: "percent",
    is_bold: false,
  },
  fmv_step_up_amount: {
    label: "FMV Step Up Amount",
    type: "dollar",
    is_bold: false,
  },
  fmv: {
    label: "FMV",
    type: "dollar",
    is_bold: true,
  },
};

export const DEAL_CREDIT_SUPPORT_SUMMARY_TYPES = {
  offtake: {
    label: "Offtake",
    type: "dollar",
    is_bold: false,
  },
  interconnection: {
    label: "Interconnection",
    type: "dollar",
    is_bold: false,
  },
  lease: {
    label: "Lease",
    type: "dollar",
    is_bold: false,
  },
  other: {
    label: "Other",
    type: "dollar",
    is_bold: false,
  },
  total: {
    label: "Total",
    type: "dollar",
    is_bold: true,
  },
};
